@import "variables.less";
@import "../../blimp_theme/less/checkout.less";
@import "forms.less";

@font-face {
  font-family: "Moderat";
  src: url("fonts/Moderat/Moderat-Black.eot"); /* IE9 Compat Modes */
  src: url("fonts/Moderat/Moderat-Black.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/Moderat/Moderat-Black.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

body.page-user {
  .header-wrapper {
    background-color: transparent !important;
  }
}
.navbar {
  background-color: transparent;
  .navigation-wrapper {
    background: transparent;
  }
  .container {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
  .navbar-header {
    display: flex;
    align-items: center;
  }
  .nav-link {
    padding-left: 8px;
    a {
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      padding: 0 6px;
      border-radius: 4px;
      line-height: 32px;
      display: inline-block;
      &:hover {
        background: rgba(255, 255, 255, 0.12);
      }
    }
  }
}
h1 {
  font-weight: 600;
  font-size: 32px;
  color: #302f45;
}
.field-name-field-subtitle {
  font-size: 15px;
  font-weight: 400;
  color: #302f45;
}
.btn {
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  height: 40px;
  border-radius: 4px;
  padding: 0 32px;
}
body.platform-styles {
  .navbar {
    background: transparent;
  }
}
body.not-logged-in.page-user-register,
body.free-trial-checkout.login_register {
  background: #fff;
  .block-commerce-checkout-progress {
    display: none;
  }
  h2.subtitle {
    color: #38485e;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  p.title-text {
    width: 100%;
    max-width: 436px;
    color: #38485e;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 36px;
  }
  .form-wrapper,
  .form-type-password-confirm {
    margin-bottom: 0;
  }
  .form-type-password-confirm .glyphicon {
    display: none;
  }
}
body.not-logged-in.page-error-not-found .divider .text {
  background-color: transparent;
}
body.not-logged-in.page-user-register,
body.free-trial-checkout.login_register {
  .navigation-wrapper .container {
    padding: 0 !important;
  }
  .field-name-field-url,
  .form-item-company,
  .form-item-last-name,
  .form-item-mail {
    margin-bottom: 16px !important;
  }
  .form-type-password-confirm {
    margin-bottom: 0;
  }
}

body.platform-styles {
  .form-submit {
    background: #4786ff;
    border-radius: 4px;
    width: 336px;
    max-width: 100%;
    height: 40px;
    padding: 0 16px;
  }
  .form-submit.btn-social {
    margin: 0 4px 0 0;
    border-radius: 4px;
    padding: 0 16px 0 38px;
    line-height: 40px;
    height: 40px;
    width: auto;
    @media (min-width: 768px) {
      padding: 0 26px 0 54px;
      margin: 0 8px 0 0;
    }
  }
}
body.platform-styles .form-control,
body.not-logged-in.page-platform-login .form-control,
body.not-logged-in.page-user .form-control {
  border-color: #f5f7fa;
  &:focus {
    border-color: @button-hover;
  }
}
#navbar .logo {
  width: auto;
}
#edit-platform-estimate-submit,
body.page-checkout-register .form-submit {
  height: 32px;
  padding: 0 16px;
  text-transform: none;
}
#edit-platform-base-platform-base-option label {
  text-transform: none;
  height: 32px;
  line-height: 32px;
}
#blimp-platform-pricing-form #features ul.active {
  li,
  li.base-feature-1,
  li.base-feature-2,
  li.base-feature-3 {
    background: transparent url("../images/icon-check.png") no-repeat 0 10px !important;
    background-size: auto 11px !important;
    padding-left: 32px !important;
  }
  li.base-feature-1,
  li.base-feature-4 {
    background-position: 0 21px !important;
  }
}
body.platform-styles {
  select,
  select.form-control {
    border-color: #f5f7fa;
    background: #f5f7fa url("../images/icon-chevron.svg") no-repeat right center !important;
    &:focus {
      background-color: #fff !important;
      border: 1px solid @button-hover;
      box-shadow: 0 0 2px @button-hover;
    }
  }
}
body.not-logged-in.page-user-register {
  #user-register-form .form-item-pass-pass1,
  #user-register-form .form-item-pass-pass2 {
    width: 284px;
    padding-bottom: 0;
  }
}
body.free-trial-checkout.review {
  .navigation-wrapper,
  .header-wrapper,
  footer.footer {
    background: transparent !important;
    font-size: 14px;
    .container {
      width: auto;
      padding: 0 40px;
    }
  }
  .navigation-wrapper {
    padding: 0;
    .container {
      max-width: 100%;
      align-items: flex-start;
      padding-top: 32px;
    }
    a.logo {
      margin: 32px;
    }
  }
  .block-commerce-checkout-progress,
  h1.page-header {
    display: none;
  }
  .checkout-help {
    background: transparent url(../images/bg-confirm-email.svg) no-repeat top
      center;
    padding-top: 298px;
    padding-bottom: 0;
    text-align: center;
    font-size: 32px;
    line-height: 33px;
    font-weight: 700;
    background-size: contain;
    width: 564px;
    margin: 0 auto 40px;
  }
  .message,
  .resend {
    width: 100%;
    max-width: 438px;
    margin: 0 auto 40px;
    color: #38485e;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    a.button {
      line-height: 38px;
      height: 40px;
      border: 1px solid #c9d0e4;
      border-radius: 2px;
      padding: 0 20px;
      color: #38485e;
      font-weight: 600;
      font-size: 15px;
      min-width: 190px;
      text-align: center;
    }
    a.button + a.button {
      margin-left: 20px;
    }
  }
  .footer-links {
    position: fixed;
    bottom: 32px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    .help,
    .with-love {
      width: 50%;
      text-align: center;
      line-height: 20px;
    }
    a {
      color: @brand-primary;
    }
    .heart {
      vertical-align: text-bottom;
      width: 16px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
#register-form {
  .form-type-checkbox {
    text-align: center;
    margin-bottom: 36px;
    label {
      color: #4b6180;
      font-size: 14px;
      line-height: 24px;
      padding-left: 34px !important;
      display: inline-block !important;
      position: relative;
    }
    input[type="checkbox"] {
      height: 18px;
      width: 18px;
      margin: 0;
      left: 0;
      top: 2px;
      &:after {
        content: "";
        height: 18px;
        width: 18px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: #fff;
        border: 1px solid #98a0ab;
        border-radius: 3px;
      }
      &:checked:before {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background: @brand-primary;
        border-radius: 1px;
        top: 4px;
        left: 4px;
        z-index: 2;
      }
    }
  }
}
body.navbar-is-fixed-top.not-logged-in.page-system-tfa {
  padding: 80px 0 !important;
  box-sizing: border-box;
  .page-wrapper {
    max-width: 670px;
    background: #fff;
    margin: 0 auto;
    padding: 32px !important;
    box-sizing: border-box;
    min-height: auto;
    height: 740px;
    margin-bottom: 0;
    border-radius: 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    &:after {
      content: none;
    }
  }
  .container {
    max-width: 100%;
  }
  .navbar-fixed-top {
    position: relative !important;
    background: transparent;
  }
  .navbar {
    background: transparent;
    max-width: 100%;
    .container {
      height: auto;
      justify-content: center;
    }
    .navbar-header {
      padding: 8px 0 40px;
    }
  }
  .header-wrapper {
    background: transparent;
    max-width: 100%;
    .header-container {
      padding-top: 0;
    }
  }
  h1.page-header {
    font-family: "Moderat", "Open sans", "Helvetica Neue", Arial;
    font-weight: 900;
    margin-bottom: 24px;
  }
  .main-container {
    height: 100%;
    .row,
    .col-sm-12,
    .region-content {
      height: 100%;
    }
  }
  .form-description {
    line-height: 24px;
    text-align: center;
    margin: 0 auto 24px;
    font-size: 15px;
    max-width: 500px;
  }
  .form-footer {
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
  }
  .footer-right {
    display: flex;
    align-items: center;
    .heart {
      line-height: 24px;
      height: 24px;
    }
  }
  #tfa-form {
    margin: 0 auto;
    width: 336px;
  }
  .checkbox {
    margin: 20px 0 40px;
    .control-label {
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
      input {
        height: 18px;
        width: 18px;
      }
    }
  }
  #edit-login {
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
    line-height: 40px;
  }
}
@media (min-width: 500px) {
  body.not-logged-in.page-user-register,
  body.free-trial-checkout.login_register {
    background-position: 0 -50px !important;
  }
}
@media (min-width: 768px) {
  body.not-logged-in.page-user-register,
  body.free-trial-checkout.login_register {
    background: #fff url("../images/signup-art@2x.png") no-repeat right bottom !important;
    background-size: 43.47% auto !important;
    background-attachment: fixed !important;
    .page-wrapper {
      width: 48.75%;
    }
    a.logo {
      position: absolute !important;
      svg path {
        fill: #1a192b !important;
      }
    }
    .header-container {
      padding: 0 !important;
    }
    header {
      padding-left: 15px;
    }
    .region-content {
      margin-right: -15px;
    }
    .block-openid-connect {
      width: 100%;
      margin: 0 auto 32px;
      button.form-submit {
        margin: 0 4px 0 0 !important;
      }
    }
  }
  #register-form {
    padding-bottom: 38px;
    .form-fields {
      width: auto !important;
    }
    .form-item.has-error {
      margin-bottom: 16px;
    }
    .password-wrapper .form-item.has-error {
      margin-bottom: 0;
    }
    button.form-submit {
      margin: 0 auto 32px !important;
    }
    .privacy {
      text-align: center;
    }
  }
  body.free-trial-checkout.login_register .header-container {
    padding: 0 40px !important;
  }
  .page-platform .container > .navbar-collapse,
  .page-checkout .container > .navbar-collapse {
    margin: 0 0 0 140px;
    width: 660px;
  }
  .navigation-wrapper {
    padding: 0;
  }
}
@media (min-width: 992px) {
  body.not-logged-in.page-user-register,
  body.free-trial-checkout.login_register {
    .page-wrapper {
      margin-right: 28% !important;
    }
  }
  #register-form {
    width: 598px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    .form-type-password-confirm {
      width: 100%;
      .row {
        &:before,
        &:after {
          content: none;
        }
      }
    }
    .flex-row,
    .form-type-password-confirm .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    .flex-row  > .form-item,
    .flex-row > .form-group {
      max-width: 48%;
    }
    .form-item-pass-pass1,
    .form-item-pass-pass2 {
      max-width: 48%;
    }
    .form-type-password {
      width: 284px !important;
    }
    .form-item-country,
    .captcha {
      margin: 0 auto 16px;
      width: 304px !important;
    }
  }
  #block-commerce-checkout-progress-indication ul li {
    width: 124px;
  }
  #block-commerce-checkout-progress-indication ul:before {
    width: 440px;
  }
}
@media (min-width: 1200px) {
  body.free-trial-checkout.login_register .header-container {
    padding: 0 !important;
  }
}
@media (min-width: 1440px) {
  body.not-logged-in.page-user-register,
  body.not-logged-in.login_register {
    .header-wrapper,
    .main-container {
      padding-left: 150px !important;
    }
    .login-footer {
      margin-left: 0;
      .container {
        width: 100%;
      }
    }
  }
}
